import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {of} from 'rxjs';
import {concatMap, take} from 'rxjs/operators';
import {CONTACT_INFO} from 'src/app/app.constants';

@Component({
    templateUrl: './imprint.component.html',
    styleUrls: ['./imprint.component.scss'],
})
export class ImprintComponent implements OnInit {

    showImprintPhoneNumber = false;
    view: string;

    constructor(
        private route: ActivatedRoute,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.route.queryParamMap
            .pipe(
                concatMap((map: ParamMap) => {
                    this.view = map.get('view');
                    const lang = map.get('lang');
                    if (lang) {
                        return this.translate.use(lang);
                    }
                    return of();
                }),
                take(1)
            )
            .subscribe();
    }

    getImprintPhoneNumber() {
        if (this.showImprintPhoneNumber) {
            return CONTACT_INFO.imprintPhone;
        }
        return `${CONTACT_INFO.imprintPhone.substring(0, 7)}...`;
    }

    setShowImprintPhoneNumber() {
        this.showImprintPhoneNumber = true;
    }
}

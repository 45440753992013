<div class="footer-route-background">
    <img class="logo" src="assets/images/finstein.svg" alt="">
    <section class="internal-page">
        <section class="internal-page-section">
            <h1 class="page-title" translate>imprint.title</h1>
            <article>
                <div class="row">
                    <div class="col-sm-6 internal-page-column pr-6">
                        <span class="internal-section-title" translate>imprint.column-1.section-1.title</span>
                        <p translate>imprint.column-1.section-1.paragraph-1</p>
                        <p>{{ 'imprint.column-1.section-1.paragraph-2' | translate: { phoneNumber: getImprintPhoneNumber() } }} <a class="false-link cursor-pointer ml-1 mr-1" *ngIf="!showImprintPhoneNumber" (click)="setShowImprintPhoneNumber()">{{'common.see-number' | translate }}</a> <i class="false-link">{{'imprint.column-1.section-1.paragraph-3' | translate }}</i></p>

                        <span class="internal-section-title mt-6" translate>imprint.column-1.section-2.title</span>
                        <p class="mb-4" translate>imprint.column-1.section-2.paragraph-1</p>
                        <p translate>imprint.column-1.section-2.paragraph-2</p>
                        <p translate>imprint.column-1.section-2.paragraph-3</p>
                        <p translate>imprint.column-1.section-2.paragraph-4</p>

                        <span class="internal-section-title mt-6" translate>imprint.column-1.section-3.title</span>
                        <p>{{ 'imprint.column-1.section-3.paragraph-1' | translate }} <a class="false-link cursor-pointer ml-1 mr-1">info&#64;finstein.de</a></p>
                    </div>

                    <div class="col-sm-6 internal-page-column pl-6">
                        <span class="internal-section-title" translate>imprint.column-2.section-1.title</span>
                        <p translate>imprint.column-2.section-1.paragraph-1</p>
                        <p translate>imprint.column-2.section-1.paragraph-2</p>
                        <p class="mt-4" translate>imprint.column-2.section-1.paragraph-3</p>
                        <p translate>imprint.column-2.section-1.paragraph-4</p>


                        <span class="internal-section-title" translate>imprint.column-2.section-2.title</span>
                        <span class="internal-section-title-bold" translate>imprint.column-2.section-2.subtitle</span>
                        <p translate>imprint.column-2.section-2.paragraph-1</p>
                        <p class="mt-4" [innerHTML]="'imprint.column-2.section-2.paragraph-2' | translate"></p>

                        <span class="internal-section-title-bold mt-6" translate>imprint.column-2.section-3.subtitle</span>
                        <p translate>imprint.column-2.section-3.paragraph-1</p>
                        <p translate>imprint.column-2.section-3.paragraph-2</p>
                        <p translate>imprint.column-2.section-3.paragraph-3</p>
                        <p [innerHTML]="'imprint.column-2.section-3.paragraph-4' | translate"></p>
                        <a href="{{ 'imprint.column-2.section-3.paragraph-5' | translate }}" target="_blank" translate>imprint.column-2.section-3.paragraph-5</a>

                        <span class="internal-section-title-bold mt-6" translate>imprint.column-2.section-4.subtitle</span>
                        <p translate>imprint.column-2.section-4.paragraph-1</p>
                        <p translate>imprint.column-2.section-4.paragraph-2</p>
                        <p [innerHTML]="'imprint.column-2.section-4.paragraph-3' | translate"></p>
                        <a href="{{ 'imprint.column-2.section-4.paragraph-4' | translate }}" target="_blank" translate>imprint.column-2.section-4.paragraph-4</a>
                        <p class="mt-4" translate>imprint.column-2.section-4.paragraph-5</p>


                        <p class="mt-4 mb-4" translate>imprint.column-2.section-5.subtitle</p>
                        <p class="pl-8" translate>imprint.column-2.section-5.paragraph-1</p>
                        <p class="pl-8" translate>imprint.column-2.section-5.paragraph-2</p>
                        <p class="pl-8" translate>imprint.column-2.section-5.paragraph-3</p>
                        <a class="pl-8 mb-4" href="{{ 'imprint.column-2.section-5.paragraph-4' | translate }}" target="_blank" translate>imprint.column-2.section-5.paragraph-4</a>

                        <p class="pl-8" translate>imprint.column-2.section-5.paragraph-5</p>
                        <p class="pl-8" translate>imprint.column-2.section-5.paragraph-6</p>
                        <p class="pl-8" translate>imprint.column-2.section-5.paragraph-7</p>
                        <a class="pl-8 mb-4" href="{{ 'imprint.column-2.section-5.paragraph-8' | translate }}" target="_blank" translate>imprint.column-2.section-5.paragraph-8</a>

                        <p class="pl-8" translate>imprint.column-2.section-5.paragraph-9</p>
                        <p class="pl-8" translate>imprint.column-2.section-5.paragraph-10</p>
                        <a class="pl-8 mb-4" href="{{ 'imprint.column-2.section-5.paragraph-11' | translate }}" target="_blank" translate>imprint.column-2.section-5.paragraph-11</a>

                        <span class="internal-section-title-bold mt-6 mb-4" translate>imprint.column-2.section-6.subtitle</span>
                        <ul class="list">
                            <li translate>imprint.column-2.section-6.paragraph-1</li>
                            <li translate>imprint.column-2.section-6.paragraph-2</li>
                            <li translate>imprint.column-2.section-6.paragraph-3</li>
                        </ul>
                        <p class="mt-4" translate>imprint.column-2.section-6.paragraph-4</p>


                        <span class="internal-section-title-bold mt-6" translate>imprint.column-2.section-7.subtitle</span>
                        <p translate>imprint.column-2.section-7.paragraph-1</p>
                        <p translate>imprint.column-2.section-7.paragraph-2</p>
                    </div>
                </div>
            </article>
        </section>
    </section>
</div>
